<template>
    <div class="subcategory" :class="[$mq]" @click="select()">
        <div class="name">{{ item.name }}</div>
    </div>
</template>

<script>
export default {
    name: 'LibraryFolder',
    props: {
        item: { type: Object, default: false }
    },
    data() {
        return {}
    },
    methods: {
        select() {
            this.$emit('openFolder')
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.subcategory {
    @include interaction();
    @include display-flex();
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content();
    @include border-radius(6px);
    @include bgHover(#fff);
    @include background($image: img('folder.svg'), $size: 40px, $position: left 6px center);
    @include flex-wrap(nowrap);
    float: left;
    width: 100%;
    height: 55px;
    overflow: hidden;
    background-color: #fff;
    padding: 9px;
    padding-left: 45px;
    cursor: pointer;

    .name {
        line-height: 19px;
        font-family: $text-bold;
        @include text-ellipsis($line: 3);
        @include font-size(sm);
        hyphens: auto;
        word-wrap: break-word;
    }

    &.portrait {
        .name {
            @include font-size(s);
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            word-wrap: break-word;
            max-width: 125px;
        }
    }
}
</style>
