<template>
    <div :data-val="file.type" :class="[{ document: type === 'column' }, { list: type === 'list' }, { selected: selected }, $mq]" @click="detailFile(file)">
        <template v-if="type == 'column'">
            <div class="image-container" v-if="file.file != null ? file.file : file.file.endsWith('.pdf') ? file.file : false">
                <cld-image
                    v-if="file.file && (file.file.endsWith('.pdf') || file.file.endsWith('.png') || file.file.endsWith('.jpeg'))"
                    class="clg-image"
                    :publicId="file.file"
                    type="fetch"
                    loading="lazy"
                    :class="{
                        hasPreview: true
                    }"
                >
                    <cld-transformation width="150" radius="3" crop="fill" fetchFormat="auto" />
                </cld-image>
                <div v-else-if="file.file && file.file.endsWith('.zip')" class="zip-background"></div>
            </div>
            <div v-else class="image" :class="{ hasPreview: file.file }" :style="file.file != null ? { backgroundImage: 'url(' + file.file + ')' } : file.file.endsWith('.pdf') ? { backgroundImage: 'url(' + file.file + ')' } : {}"></div>

            <div class="title" :class="{ pdf: file.file.endsWith('.pdf') }">
                <div class="name" :title="file.name">{{ file.name }}</div>
            </div>

            <div class="meta">
                <!-- <div class="update">
                <template v-if="!['portrait'].includes($mq)">{{ $t('library.last_update') }} </template> {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}
            </div> -->
                <div v-if="file.highlight" class="highlight"></div>
            </div>
        </template>
        <template v-else>
            <div class="filetype" :class="{ pdf: file.file.endsWith('.pdf') }" :data-val="file.type"></div>
            <div class="info-file" :class="{ haveStar: file.highlight }">
                <div class="title">
                    {{ file.name }}
                </div>
                <div class="meta">{{ $t('library.last_update') }} {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}</div>
            </div>
            <div class="is-highlighted" v-if="file.highlight"></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FileItem',

    props: {
        file: {
            type: [Array, Object],
            default: []
        },
        type: {
            type: String,
            default: 'column'
        }
    },
    data() {
        return {
            selected: true
        }
    },
    created() {
        // this.updateSelected( true );
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    methods: {
        detailFile(file) {
            this.$router.push({ path: `/library/${file.id}` })
        }
    }
}
</script>

<style lang="scss" scoped>
.document {
    position: relative;
    // DEFAULT IMAGES IF TYPE = NULL
    .image {
        @include background($size: 65px, $color: $neutro-t60, $image: img('file_neutro.svg'));
    }
    .title {
        @include background($size: 18px, $image: img('file_neutro_s90.svg'));
    }

    // IMAGES BY TYPES
    &[data-val='1'] {
        .image {
            @include background($size: 70px, $color: $neutro-t60, $image: img('image_neutro.svg'));
        }
        .title {
            @include background($size: 20px, $image: img('image.svg'));
        }
    }

    &[data-val='2'] {
        .image {
            @include background($size: 50px, $color: $neutro-t60, $image: img('video.svg'));
        }
        .title {
            @include background($size: 13px, $position: bottom, $image: img('video.svg'));
        }
    }

    &[data-val='3'] {
        .image {
            @include background($size: 65px, $color: $neutro-t60, $image: img('file_neutro.svg'));
        }
        .title {
            @include background($size: 40px, $position: bottom, $image: img('zip.svg'));

            &.pdf {
                @include background($size: 18px, $position: bottom, $image: img('pdf_file.svg'));
            }
        }
    }

    @include interaction();
    @include display-flex();
    @include flex-direction(column);
    @include align-items($val: flex-start);
    @include justify-content();
    @include border-radius(6px);
    width: 100%;
    min-height: 150px;
    overflow: hidden;
    background-color: #fff;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding: 6px;

    @include bgHover(#fff);

    .image-container {
        width: calc(100%);
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        .clg-image {
            @include border-radius(3px);
            width: 100%;
            height: auto;
            max-height: 90px;
            object-fit: contain;
        }
        .zip-background {
            @include background($size: 120px, $position: bottom, $image: img('zip.svg'));
            height: 120px;
            width: 120px;
        }
    }

    .image {
        @include border-radius(3px);
        width: calc(100%);
        height: 120px;
        display: inline-block;

        &.hasPreview {
            @include background($size: contain, $color: $main-t90, $image: img('blank_document.svg'));
        }
    }

    .title {
        background-position: left center !important;
        @include display-flex();
        @include align-items();
        margin-top: 3px;
        height: 50px;
        width: 100%;

        .name {
            @include display-inline-flex();
            @include align-items();
            @include font-size(s);
            @include text-ellipsis($line: 3);
            line-height: 1.2; // PORFABOR NO TOCAR
            // text-indent: 3px;
            color: $neutro-s80;
            font-family: $text;
            width: 100%;
            height: auto;
            padding-left: 30px;
            margin-top: 3px;
            overflow-wrap: break-word;

            // &:before {
            //     display: inline-block;
            //     height: 14px;
            //     width: 14px;
            //     content: '';
            //     margin-right: 3px;
            // }
        }
    }

    .meta {
        // margin-top: 3px;
        width: 100%;

        .update {
            @include font-size(s);
            color: $neutro-s70;
            float: left;
            width: calc(100% - 15px);
            font-family: $conden-light;
        }

        .highlight {
            float: right;
            width: 15px;
            height: 15px;
            top: 12px;
            right: 12px;
            position: absolute;
            padding: 12px;
            @include background($image: img('star_main.svg'), $size: 20px, $color: #fff);
        }
    }
}

// list styles
.list {
    width: 100%;
    background-color: #fff;
    height: 80px;
    padding: 8px 0px;
    @include border-radius(4px);
    @include display-flex();
    @include align-items();
    @include justify-content(flex-start);
    cursor: pointer;

    .filetype {
        width: 50px;
        height: 50px;
        // IMAGES BY TYPES
        &[data-val='1'] {
            @include background($size: 20px, $image: img('image.svg'));
        }

        &[data-val='2'] {
            @include background($size: 20px, $image: img('video_neutro.svg'));
        }

        &[data-val='3'] {
            @include background($size: 25px, $image: img('zip.svg'));
        }
        &.pdf {
            @include background($size: 25px, $image: img('PDF.svg'));
        }
    }

    .info-file {
        @include display-flex();
        @include flex-direction(column);
        width: calc(100%);
        padding-right: 8px;

        .title {
            @include text-ellipsis($line: 2);
            width: calc(100%);
            font-family: $text;
            color: $neutro-s80;
            @include font-size(s);
        }
        .meta {
            @include font-size(xs);
            @include text-ellipsis();
            width: 100%;
            font-family: $text;
            color: $neutro-s50;
        }
        &.haveStar {
            width: calc(100% - 90px);
        }
    }

    .is-highlighted {
        width: 25px;
        height: 25px;
        @include background($image: img('star_main.svg'), $size: 25px, $color: #fff);
        min-width: 25px;
        min-height: 25px;
    }
    &:hover {
        @include bgHover(#fff);
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.portrait.list {
    .info-file {
        width: calc(100% - 50px);
        .title {
            font-size: 14px;
            .name {
                @include font-size(xs);
            }
            .meta {
                .update {
                    @include font-size(xs);
                }
            }
        }
    }
}
</style>
